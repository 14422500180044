<template>
  <vx-card title="Detail Collection Plan">
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" v-model="territory" readonly />
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Date</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" v-model="date" readonly />
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Sales</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" v-model="sales" readonly />
      </div>
    </div>
    <div class="vx-row w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Total Value</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" readonly v-model="total_value" />
      </div>
    </div>
    <div class="vx-row w-1/2 mt-2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Notes</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-textarea class="w-full" v-model="notes" readonly />
      </div>
    </div>

    <!-- table -->
    <div class="vx-row">
      <div class="core vx-col w-full">
        <vs-tabs>
          <vs-tab label="Checked Invoices">
            <div class="con-tab-ejemplo">
              <vs-table
                search
                stripe
                border
                description
                :sst="false"
                :data="table.data"
                :max-items="table.data.length"
              >
                <template slot="thead">
                  <vs-th>Sequence</vs-th>
                  <vs-th>Customer</vs-th>
                  <vs-th>Ship To</vs-th>
                  <vs-th>Invoice No</vs-th>
                  <vs-th>Invoice Date</vs-th>
                  <vs-th>Due Date</vs-th>
                  <vs-th>Invoice Value</vs-th>
                  <vs-th>Remaining Value</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td>{{ tr.sequence }}</vs-td>
                    <vs-td>{{ tr.customer }}</vs-td>
                    <vs-td>{{ tr.ship_to }}</vs-td>
                    <vs-td>{{ tr.invoice_no }}</vs-td>
                    <vs-td>{{ tr.invoice_date | formatDate }}</vs-td>
                    <vs-td>{{ tr.due_date | formatDate }}</vs-td>
                    <vs-td>{{ tr.invoice_value | formatNumber }}</vs-td>
                    <vs-td>{{ tr.remaining_value | formatNumber }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-tab>
          <vs-tab label="Unchecked Invoices">
            <div class="con-tab-ejemplo">
              <vs-table
                search
                stripe
                border
                description
                :sst="false"
                :data="table_uncheckeds.data"
                :max-items="table_uncheckeds.data.length"
              >
                <template slot="thead">
                  <vs-th>Sequence</vs-th>
                  <vs-th>Customer</vs-th>
                  <vs-th>Ship To</vs-th>
                  <vs-th>Invoice No</vs-th>
                  <vs-th>Invoice Date</vs-th>
                  <vs-th>Due Date</vs-th>
                  <vs-th>Invoice Value</vs-th>
                  <vs-th>Remaining Value</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td>{{ tr.sequence }}</vs-td>
                    <vs-td>{{ tr.customer }}</vs-td>
                    <vs-td>{{ tr.ship_to }}</vs-td>
                    <vs-td>{{ tr.invoice_no }}</vs-td>
                    <vs-td>{{ tr.invoice_date | formatDate }}</vs-td>
                    <vs-td>{{ tr.due_date | formatDate }}</vs-td>
                    <vs-td>{{ tr.invoice_value | formatNumber }}</vs-td>
                    <vs-td>{{ tr.remaining_value | formatNumber }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-tab>
        </vs-tabs>

        <div class="vs-row mt-4">
          <div class="vs-col">
            <vs-button
              @click="onClickBack"
              size="medium"
              color="danger"
              icon-pack="feather"
              icon="icon-arrow-left"
              >Back</vs-button
            >
          </div>
          <div class="vs-col flex gap-2 ml-4">
            <vs-button
              @click="onClickStatus(99)"
              size="medium"
              color="warning"
              icon-pack="feather"
              icon="icon-x"
              >Reject</vs-button
            >
            <vs-button
              @click="onClickStatus(1)"
              size="medium"
              color="success"
              icon-pack="feather"
              icon="icon-check"
              >Approve</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import { formatNumber } from "../../../utils/common";

export default {
  data() {
    return {
      table: this.tableDefaultState(),
      total_value: 0,
      territory: null,
      sales: null,
      date: null,
      routes: [],
      notes: null,
      table_uncheckeds: {
        data: [],
      },
    };
  },
  mounted() {
    this.getOneData();
    this.getCollectionUncheckeds(this.$route.params.id);
  },
  methods: {
    async onClickStatus(status) {
      this.$vs.loading();
      const id = this.$route.params.id;
      let response = await this.$http.put(
        `api/sfa/v1/collection-plan/${id}/status`,
        {
          status: status,
        }
      );

      if (response.code == 200) {
        this.$vs.notify({
          title: "Success",
          text: "Success update status",
          color: "success",
        });
        this.$router.go(-1);
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Error update status",
          color: "danger",
        });
      }

      this.$vs.loading.close();
    },
    async getOneData() {
      this.$vs.loading();
      const id = this.$route.params.id;
      let response = await this.$http.get(
        `/api/sfa/v1/collection-plan/${id}/detail`
      );

      let data = response.data;
      this.territory = data.territory;
      this.notes = data.notes;
      this.sales = data.sales;
      this.date = moment(data.date).format("DD-MM-YYYY");
      // this.total_value = data.bill_total;
      this.table.data = data.detail_items;
      data.detail_items.forEach((element) => {
        this.total_value += element.remaining_value;
      });
      this.$vs.loading.close();
    },
    async getCollectionUncheckeds(collection_plan_id) {
      if (collection_plan_id == null) {
        this.$vs.notify({
          title: "Warning",
          text: "collection_plan_id is required",
          color: "warning",
        });
        return;
      }
      this.$vs.loading();
      let response = await this.$http.get(
        `/api/sfa/v1/collection-plan/list-collection-billing-uncheck`,
        {
          params: {
            collection_plan_id: collection_plan_id,
            length: 0,
          },
        }
      );

      if (response.code != 200) {
        this.$vs.notify({
          title: "Error",
          text: response.message,
          color: "danger",
        });
        this.$vs.loading.close();
        return;
      }

      if (response.data.records != null) {
        this.table_uncheckeds.data = response.data.records;
      }
      this.$vs.loading.close();
    },
    tableDefaultState() {
      return {
        data: [],
      };
    },
    onClickBack() {
      // back previous page
      this.$router.go(-1);
    },
  },
  filters: {
    formatNumber(value) {
      if (value) {
        return formatNumber(value);
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },
};
</script>
